@import "./_constants.scss";

body {
  margin: 0;
  padding: 0;
  font-family: "Nexa Light", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

@font-face {
  font-family: 'Open Sans Cond Light';
  src: url('fonts/OpenSans-CondLight.ttf');
}

@font-face {
  font-family: 'Open Sans Cond Bold';
  src: url('fonts/OpenSans-CondBold.ttf');
}

@font-face {
  font-family: 'Open Sans Regular';
  src: url('fonts/OpenSans-Regular.ttf');
}

button {
  font-family: "Open Sans", serif;
}

h1 {
  font-family: "Open Sans Cond Bold", serif;
}

h3 {
  font-family: "Open Sans Cond Light", serif;
}


@each $size in $sizes {
  .mt-#{$size} {
    margin-top: #{$size}px !important;
  }
  .ml-#{$size} {
    margin-left: #{$size}px !important;
  }
  .mr-#{$size} {
    margin-right: #{$size}px !important;
  }
  .mb-#{$size} {
    margin-bottom: #{$size}px !important;
  }
  .m-#{$size} {
    margin: #{$size}px !important;
  }
  .pt-#{$size} {
    padding-top: #{$size}px !important;
  }
  .pl-#{$size} {
    padding-left: #{$size}px !important;
  }
  .pr-#{$size} {
    padding-right: #{$size}px !important;
  }
  .pb-#{$size} {
    padding-bottom: #{$size}px !important;
  }
  .p-#{$size} {
    padding: #{$size}px !important;
  }
}

.is-space-evenly {
  justify-content: space-evenly !important;
}

.is-jc-center {
  justify-content: center !important;
}

.title-message {
  font-size: 1.3rem;
  font-weight: bold;
}

.is-custom-back{
  background-color:  #efefef !important;
  font-family: "Open Sans Regular", sans-serif !important; 

}
